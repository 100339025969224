<template>
<div class="container" v-if="permission === 1">
<CRow class="m-auto d-flex justify-content-center">
     <CCol sm='4' lg='4' class="small1 text-center">
       <span>{{$t('message.totalWithdrawnMoney')}} </span><br><br>
       <span class="h4">{{totalWithdrawn | number}}</span>
    </CCol>
     <CCol sm='4' lg='4' class="small2 text-center">
       <span>{{$t('message.totalReceivedMoney')}} </span><br><br>
       <span class="h4">{{totalReceived | number}}</span>
    </CCol>
     <CCol sm='4' lg='4' class="small3 text-center">
       <span>{{$t('message.netAmount')}} </span><br><br>
       <span class="h4">{{net | number}}</span>
    </CCol>
  </CRow>
   <CRow class="mt-5">
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
         class="widget"
        :header='categoriesCount'
        :text="$t('message.numberOfCategories')"
        inverse
      >
     <CIcon name='cil-speedometer' height='45'/>
      </CWidgetProgressIcon>
    </CCol>
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
       class="widget"
        :header='productsCount'
        :text="$t('message.numberOfProducts')"
        inverse
      >
      <CIcon name='cil-basket' height='45'/>
      </CWidgetProgressIcon>
       </CCol>
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
       class="widget"
        :header='customersCount'
        :text="$t('message.totalCustomers')"
        inverse
      >
        <CIcon name='cil-chartPie' height='45'/>
      </CWidgetProgressIcon>
    </CCol>
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
       class="widget"
        :header='ordersCount'
        :text="$t('message.numberOfOrders')"
        inverse
      >
        <CIcon name='cil-speedometer' height='45'/>
      </CWidgetProgressIcon>
    </CCol>
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
       class="widget"
        :header='suppliersCount'
        :text="$t('message.numberOfSuppliers')"
        inverse
      >
        <CIcon name='cil-chartPie' height='45'/>
      </CWidgetProgressIcon>
    </CCol>
    <CCol sm='6' lg='4'>
      <CWidgetProgressIcon
       class="widget"
        :header='employeesCount'
        :text="$t('message.numberOfEmployees')"
        inverse
      >
        <CIcon name='cil-people' height='45'/>
      </CWidgetProgressIcon>
    </CCol>
  </CROW>
</div>

</template>

<script>

export default {
  name: 'Widgets',
  data () {
    return {
      categoriesCount: '0',
      productsCount: '0',
      customersCount: '0',
      ordersCount: '0',
      suppliersCount: '0',
      employeesCount: '0',
      jobsCount: '0',
      totalWithdrawn: '0',
      totalReceived: '0',
      net: '0',
      permission: 0
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}statistics`)
      .then((response) => {
        if (response) {
          this.permission = 1
          this.categoriesCount = response.data.data.statistics_categories.toString()
          this.productsCount = response.data.data.statistics_products.toString()
          this.customersCount = response.data.data.statistics_customers.toString()
          this.ordersCount = response.data.data.statistics_orders.toString()
          this.suppliersCount = response.data.data.statistics_suppliers.toString()
          this.employeesCount = response.data.data.statistics_employees.toString()
          this.jobsCount = response.data.data.statistics_jobs.toString()
        }
      })
    this.$http
      .get(`${this.$hostUrl}report/accounting`)
      .then((response) => {
        this.totalWithdrawn = response.data.data.total_withdrawn_money.toString()
        this.totalReceived = response.data.data.total_received_money.toString()
        this.net = response.data.data.total_received_money - response.data.data.total_withdrawn_money
        this.net = this.net.toString()
      })
  }
}
</script>
<style scoped>
.h4 {
  font-size: 2.4rem !important;
}
</style>
<style>
.widget
{
  background-color: #eef1f5;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  border-radius: 20px;
  }
  .small1, .small2, .small3{
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  color:#fff;
  padding: 30px 60px;

  }
  .small1 .h4 , .small2 .h4, .small3 .h4{
    color:#fff !important;
  }
  .small1{
    background:  linear-gradient(#5770a3, #1310df,#5d789f)
  }
  .small2{
    background:  linear-gradient(#cd3a78, #ce3a78,#9f5d78)
  }
  .small3{
    background:  linear-gradient(#2f842f, #165c16, #4fb64f)
  }

</style>
